<template>
    <div class="paybox">
            <div class="logo"><img src="../../assets/img/success.png" alt=""></div>
            <div class="title">支付成功</div>
            <div class="item">支付金额 <span>￥99</span></div>
            <div class="item">订单编号 <span>6564543123431</span></div>
            <div class="paybtn">完成</div>
           <Msg :msg='msg' v-if="showmsg" /> 
    </div>
</template>
<style>
html,body{background: #fefefe;}
.paybox{margin: auto;padding:30px;font-size: 14px; background: #fff;}
.paybox .title{text-align: center;padding: 10px;    margin-bottom: 30px;}
.paybox .item{padding: 10px 0;}
.paybox .item span{float: right;}

.paybox .logo{    width:64px;    height: 64px;    margin: auto;    border-radius: 4px;    overflow: hidden;}
.paybox .paybtn{    text-align: center;    padding: 14px 0;    background: #ffdf20;    margin: 20px 0;    border-radius: 6px;    color: #333;}
</style>
<script>
import Msg from '../../components/msg'
export default {
        name: 'success',
        components: {Msg},
        data() {
            return {
                    msg:'',
                    showmsg:false,
            }
        },
        mounted(){
              document.title = '支付成功';
              this.orderNo=this.$route.query.orderNo;
              this.getOrder(this.orderNo);
        },
        methods: {
            getOrder(orderNo){
                    let that = this;
                    that.showmsg=true;
                    that.msg=orderNo;
                    return false;
            }
        }
}
</script>