<template>
   <div class="msk"> 
        <div class="icon" v-if="icon">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
      </div>
      {{msg}}
    </div>
</template>
<script>
  export default {
      name: 'msg',
      props: {
          msg: {type: String,
              default: '加载中'},
          icon:{  
            type: Boolean,
            default: false
          }
      }
 
  };
</script>
<style>
.msk{position: fixed;
      background: #000000;
      padding:15px 20px;
      color: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 6px;
      opacity: 0.7;
          text-align: center;
    line-height: 18px;
    }
    .icon{
      width: 40px;
      height: 40px;
      position: relative;
      margin: 8px 10px;
      background-color: #000000;
      margin-bottom: 10px;
}
.icon span{
	width: 8px;
	height: 8px;
	background-color: #fff;
	position: absolute;
	border-radius: 50%;
	-webkit-animation: load 1.04s ease infinite;
}
@-webkit-keyframes load {
    0% {
        -webkit-transform: scale(1.2);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(.3);
        opacity: 0.5;
    }
}
.icon span:nth-child(1){
	left: 0;
	top: 50%;
	margin-top: -4px;
	-webkit-animation-delay: 0.13s;
}
.icon span:nth-child(2){
	left: 5px;
	top:5px;
	-webkit-animation-delay: 0.26s;
}
.icon span:nth-child(3){
	left: 50%;
	top:0;
	margin-left: -4px;
	-webkit-animation-delay: 0.39s;
}

.icon span:nth-child(4){
	right: 5px;
	top:5px;
	-webkit-animation-delay: 0.52s;
}
.icon span:nth-child(5){
	right: 0;
	top: 50%;
	margin-top: -4px;
	-webkit-animation-delay: 0.65s;
}
.icon span:nth-child(6){
	right: 5px;
	bottom:5px;
	-webkit-animation-delay: 0.78s;
}
.icon span:nth-child(7){
	left: 50%;
	bottom:0;
	margin-left: -4px;
	-webkit-animation-delay: 0.91s;
}
.icon span:nth-child(8){
	left: 5px;
	bottom:5px;
	-webkit-animation-delay: 1.04s;
}

</style>